<template>
  <div class="sectionPad nn pt-5">
    <div class="container nep">
      <h3>Testimonials</h3>
      <!-- <p>A short testimonial</p> -->
      <div class="row">
        <div class="col-lg-6">
          <div class="headln">
            <h5>How I ditched diesel</h5>
            <p>Dapo Osunkoya</p>
            <span>Finance Professional</span>
          </div>
          <div class="vid-2-cont vid-cont">
            <video-player
              :options="player2Options"
              :playsinline="true"
              src="/assets/breakup2.mp4"
            ></video-player>    
          </div>
        </div>
        <div class="col-lg-6">
          <div class="headln">
            <h5>How I divorced 'NEPA'</h5>
            <p>Mayowa Ojo</p>
            <span>Product Designer</span>
          </div>
          <div class="vid-cont">
            <video-player
              :options="playerOptions"
              :playsinline="true"
              src="/assets/breakup.mp4"
            ></video-player>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";

import { videoPlayer } from "vue-video-player";

export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      playerOptions: {
        // videojs options
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        height: "800",
        sources: [
          {
            type: "video/mp4",
            src: "/assets/breakup.mp4",
          },
        ],
        poster: "/assets/images/hidn.png",
      },
      player2Options: {
        // videojs options
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        height: "800",
        sources: [
          {
            type: "video/mp4",
            src: "/assets/breakup2.mp4",
          },
        ],
        poster: "/assets/images/banner2.png",
      },
    };
  },
};
</script>

<style>
.vjs-icon-play,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder,
.vjs-icon-fullscreen-enter,
.video-js .vjs-fullscreen-control .vjs-icon-placeholder,
.vjs-icon-volume-high,
.video-js .vjs-mute-control .vjs-icon-placeholder {
  font-family: VideoJS !important;
  font-weight: normal;
  font-style: normal;
}
.nep h3 {
  font-size: 40px;
  color: #219653;
  font-weight: bold;
  text-align: center;
}
.nn {
  /* background: #f5fcf2; */
  background: linear-gradient(180deg, #f5fcf2, #fff);
}
.nep p {
  font-weight: 300;
  font-size: 22px;
  color: #202020;
  text-align: center;
}
.video-js .vjs-big-play-button {
  position: relative;
}
div#vjs_video_3,
div#vjs_video_433 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  overflow: hidden;
  /* height: 60vh; */
  height: 100%;
}
.vjs-poster {
  background-size: 90%;
}
.video-js {
  height: 100%;
}
.video-js .vjs-control-bar {
  background-color: transparent;
}
.vid-cont {
  margin: auto;
  margin-top: 16px;
  height: 60vh;
  max-width: 448px;
  position: relative;
}
.vid-cont .video-player {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headln{
  max-width: 448px;
  margin: auto;
  margin-top: 40px;
}
.headln h5{
  color: #219653;
  font-weight: 600;
  /* font-size: 1rem; */
}
.headln p{
  text-align: left;
  margin: 0;
  margin-top: 12px;
  font-weight: 600;
  color: #CBDFAB;
}
.headln span{
  font-weight: 500;
  font-size: .9rem;
  margin: 0;
  display: inline-block;
  /* color: #F8D934; */
}
</style>